'use client';

import * as React from 'react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@av/ui/carousel';
import { Button } from '@av/ui/button';
import { FeatureValue } from '@av/utils';
import { Section } from '@/components/section/section';
import BlockHeading from '../components/block-heading';
import Image from 'next/image';

const Features = ({ items, ...props }: { items: FeatureValue[] }) => {
  return (
    <Carousel className="w-screen bg-[#F4F7FF]" {...props} opts={{ loop: true }}>
      <CarouselContent>
        {items.map(({ title, subtitle, description, button, media }, index) => (
          <CarouselItem key={index}>
            <Section
              parentClassName="h-full"
              className="grid h-full items-center px-4 max-md:pb-20 max-md:pt-10 md:grid-cols-2"
              padding="none"
              gap="none"
            >
              <div className="flex flex-col space-y-4 py-8">
                <div className="text-base font-semibold text-primary md:text-lg">{subtitle}</div>
                <BlockHeading as="h1">{title}</BlockHeading>
                <p className="max-md:text-main text-sm md:text-base md:text-description">
                  {description}
                </p>
                {!!button.children && (
                  <div className="pt-5">
                    <Button className="px-8" {...button} />
                  </div>
                )}
              </div>
              <div className="md:relative md:h-full">
                <svg
                  width="132"
                  height="560"
                  viewBox="0 0 132 560"
                  className="absolute left-0 h-full w-auto max-md:hidden"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M131.5 0H106.5L0 560H29.5L131.5 0Z" fill="#E7EEFF" stroke="#E7EEFF" />
                  <path d="M0 560L106.5 0H0V560Z" fill="#F4F7FF" />
                </svg>
                <Image
                  src={media?.url || '/images/wedding.png'}
                  height={media?.height || 532}
                  width={media?.width || 800}
                  alt="feature"
                  className="h-full w-[calc(50vw+0.5rem)] max-w-[calc(50vw+0.5rem)] object-cover max-md:absolute max-md:inset-0 max-md:-z-[1] max-md:w-full max-md:max-w-full max-md:rounded-lg max-md:opacity-30"
                />
              </div>
            </Section>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-4 max-md:bottom-0 max-md:top-auto" />
      <CarouselNext className="right-4 max-md:bottom-0 max-md:top-auto" />
    </Carousel>
  );
};

export default Features;
