'use client';

import { useAccessories } from '@/app/components/accessories/accessories-context';
import ProductQuantityForm from '@/app/components/product-quantity-form';
import { Product } from '@av/database';
import { Button } from '@av/ui/button';
import { Form } from '@av/ui/form';
import { CartItemForm, CartItemFormSchema } from '@av/validators';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTransition } from 'react';
import { useForm } from 'react-hook-form';

const ProductCardForm = ({ product }: { product: Product }) => {
  const [loading, startTransition] = useTransition();
  const { productAccessoriesHandler } = useAccessories();

  const form = useForm<CartItemForm>({
    resolver: zodResolver(CartItemFormSchema),
    defaultValues: {
      quantity: product.minQuantity || 1,
      productId: product.id,
    },
  });

  const onSubmit = async (data: CartItemForm) => {
    startTransition(async () => {
      await productAccessoriesHandler({ product, quantity: data.quantity }, () => {
        form.reset();
      });
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full space-x-1.5">
        <ProductQuantityForm loading={loading} {...product} />
        <Button className="h-[38px] w-full px-2">Toevoegen</Button>
      </form>
    </Form>
  );
};

export default ProductCardForm;
