'use client';

import { ImgNotAvailable } from '@/assets/img-not-available';
import { Product } from '@av/database';
import { CardTitle } from '@av/ui/card';
import { ImageArray, formatSrcSet, formatter } from '@av/utils';
import Link from 'next/link';
import ProductCardForm from './product-card-form';
import { useTaxState } from '../../../components/context/tax-state-context';

const ProductCard = ({ product }: { product: Product & { images?: ImageArray } }) => {
  const link = `/product/${product.id}/${product.slug}/${product.code}`;
  const ariaLabel = `Navigate to ${product.title}`;
  const { isTaxIncluded } = useTaxState();

  const taxFactor = product.taxRate ? product.taxRate / 100 : 0;
  const priceFactor = isTaxIncluded ? 1 + taxFactor : 1;
  const productPrice = ((product.unitPrice || 0) * priceFactor) / 100;
  const srcSet =
    product.images && !process.env.NEXT_PUBLIC_USE_SRC_SET
      ? formatSrcSet(product.images)
      : undefined;

  return (
    <div className="group flex size-full flex-col rounded-md border bg-white shadow-sm transition-all hover:shadow-lg">
      <Link
        className="relative flex aspect-[4/3] w-full items-center justify-center border-b px-4"
        aria-label={ariaLabel}
        href={link}
      >
        {product.thumbnailImage ? (
          <img
            src={product.thumbnailImage}
            alt={product.title}
            srcSet={srcSet}
            className="aspect-[4/3] size-full object-contain py-4"
            sizes="(max-width: 639px) calc(100vw - 64px), (min-width: 640px) and (max-width: 1002px) calc(100vw - 346px), (min-width: 1003px) and (max-width: 1227px) calc((100vw - 410px) / 2), (min-width: 1228) 254px"
          />
        ) : (
          <ImgNotAvailable className="aspect-[4/3]" />
        )}
      </Link>
      <Link className="flex justify-between space-x-2 p-4" aria-label={ariaLabel} href={link}>
        <CardTitle
          className="leading-2 max-w-full overflow-hidden text-ellipsis font-normal group-hover:underline"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {product.title}
        </CardTitle>
        <strong className="text-base">{formatter.format(productPrice)}</strong>
      </Link>
      <div className="mt-auto p-4 pt-0">
        <ProductCardForm product={product} />
      </div>
    </div>
  );
};

export default ProductCard;
