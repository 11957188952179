import { SearchResponse } from '@algolia/client-search';
import { GatewayProduct } from '@av/gateway-integration';

export type { MultipleQueriesResponse } from '@algolia/client-search';

export interface SearchProduct extends GatewayProduct {
  categories: {
    lv0: string;
    lv1?: string;
  };
  titleSuffixes?: string[];
  minQuantity?: number;
  setQuantity?: number;
  step?: number;
  objectID?: string | number;
  price?: number;
  specificationValues: Record<string, string>;
}

export type AlgoliaObject = SearchProduct;

export enum Indices {
  PRODUCTS = 'artikelen',
}

export type SearchProductResponse = SearchResponse<SearchProduct>;
